import styled from 'styled-components';
import {Link} from 'gatsby';
import React from 'react';
import ScreenReaderText from './ScreenReaderText';
import {colors} from '../../theme';

const StyledLink = styled(Link)`
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  &:focus,
  &:hover {
    & > .more-text {
      color: #fff;
      border-color: ${colors.darkTextColor};
      background-color: ${colors.darkTextColor};
      transition: all 0.1s linear;
    }
  }

  .more-text {
    display: inline-block;
    padding: 6px 18px;
    font-size: 12px;
    text-transform: uppercase;
    border: 2px solid ${colors.darkTextColor};
    border-radius: 25px;
    color: ${colors.darkTextColor};
    background-color: transparent;
    transition: all 0.1s linear;
  }
`;

const EntryMoreLink = ({articleTitle, articleLink, moreText = 'Read More'}) => (
  <StyledLink to={articleLink || '/'}>
    <span className="more-text">{moreText}</span>
    <ScreenReaderText>{articleTitle}</ScreenReaderText>
  </StyledLink>
);

export default EntryMoreLink;
