import React, {useMemo} from 'react';
import styled from 'styled-components';
import striptags from 'striptags';
import ArticleEntryFeature from './ArticleEntryFeature';
import ArticleEntryHeader from './ArticleEntryHeader';
import EntryMoreLink from '../EntryMoreLink';
import {breakpoints} from '../../../theme';

const PostContainer = styled.article`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  align-items: center;
  position: relative;
  margin-bottom: 4rem;

  &::after,
  &::before {
    content: '';
    display: table;
    clear: both;
  }

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    margin-bottom: 2rem;
  }
`;

const EntryContainer = styled.div`
  flex: 1;
  padding: 20px 40px 10px;
  text-align: center;

  @media screen and (max-width: ${breakpoints.tabletMax}) {
    text-align: left;
    padding-left: 20px;
    padding-right: 0;
  }
`;

const EntryContent = styled.div`
  @media screen and (max-width: ${breakpoints.tabletMax}) {
    display: none;
  }
`;

const EXCERPT_WORD_COUNT = 30;

const ArticleEntryTemplate = ({
  className,
  displayMode,
  title,
  content,
  excerpt,
  publishedAt,
  articleLink,
  category,
  categoryTitle,
  categoryLink,
  featuredAttachment,
}) => {
  const summary = useMemo(() => {
    if (excerpt && excerpt.length) return excerpt;
    const sansTags = striptags(content).replace('&nbsp;', ' ');
    const words = sansTags.split(' ');
    return `${words.slice(0, EXCERPT_WORD_COUNT).join(' ')} ...`;
  }, [content, excerpt]);

  const vertical = displayMode === 'vertical';
  return (
    <PostContainer className={className} vertical={vertical}>
      <ArticleEntryFeature
        displayMode={displayMode}
        attachment={featuredAttachment}
      />
      <EntryContainer>
        <ArticleEntryHeader
          title={title}
          publishedAt={publishedAt}
          articleLink={articleLink}
          categoryTitle={categoryTitle}
          categoryLink={categoryLink}
        />

        <EntryContent>
          <p className="text-center">
            {summary}
            <EntryMoreLink articleTitle={title} articleLink={articleLink} />
          </p>
        </EntryContent>
      </EntryContainer>
    </PostContainer>
  );
};

export default ArticleEntryTemplate;
