import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import HomePage from '../components/shared/HomePage/HomePage';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ahp {
        homePageData {
          value
        }
        recentArticles(paginate: {first: 3}) {
          ...ArticleListItemFragment
        }
      }
    }
  `);

  return (
    <HomePage
      entry={data?.ahp?.homePageData?.value}
      articles={data?.ahp?.recentArticles?.nodes}
    />
  );
};

export default IndexPage;
