import React from 'react';
import styled from 'styled-components';
import {Image} from 'semantic-ui-react';

const FeatureContainer = styled.div`
  width: ${props => (props.vertical ? '100%' : '45%')};
  max-width: ${props => (props.vertical ? '1000px' : '300px')};
  margin: 0;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const FeatureImage = ({attachment, displayMode}) => {
  const {title, altText, urls} = attachment;
  const src = urls.regular || urls.full || urls.original;
  const vertical = displayMode === 'vertical';
  return (
    <FeatureContainer vertical={vertical}>
      <Image src={src} title={title} alt={altText || title} fluid />
    </FeatureContainer>
  );
};

const FeatureVideo = ({attachment, displayMode}) => {
  const {
    title,
    altText,
    // contentData: { id, service },
    previewImageUrl,
  } = attachment;
  const vertical = displayMode === 'vertical';
  return (
    <FeatureContainer vertical={vertical}>
      <Image src={previewImageUrl} title={title} alt={altText || title} fluid />
      {/* <Embed id={id} source={service} placeholder={previewImageUrl} /> */}
    </FeatureContainer>
  );
};

const ArticleEntryFeature = ({attachment, displayMode}) => {
  if (!attachment) {
    return null;
  }

  if (attachment.attachmentType === 'VIDEO') {
    return <FeatureVideo attachment={attachment} displayMode={displayMode} />;
  }

  return <FeatureImage attachment={attachment} displayMode={displayMode} />;
};

export default ArticleEntryFeature;
