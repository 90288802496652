import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import EntryTitle from '../EntryTitle';
import EntryMeta from '../EntryMeta';

const HeaderContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const ArticleEntryHeader = ({
  title,
  publishedAt,
  articleLink,
  categoryTitle,
  categoryLink,
}) => (
  <HeaderContainer>
    <EntryTitle as="h2" size="small">
      <Link to={articleLink || '/'}>{title || '<No Title>'}</Link>
    </EntryTitle>
    <EntryMeta
      articleLink={articleLink}
      categoryTitle={categoryTitle}
      categoryLink={categoryLink}
      publishedAt={publishedAt}
    />
  </HeaderContainer>
);

export default ArticleEntryHeader;
