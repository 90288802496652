import styled from 'styled-components';

const ScreenReaderText = styled.span`
  clip: rect(1px,1px,1px,1px);
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden
  
  &:focus {
    background-color: #fafafa;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.6);
    clip: auto!important;
    color: #597c2c;
    display: block;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
  }
`;

export default ScreenReaderText;
