import React from 'react';
import styled from 'styled-components';
import ArticleEntryTemplate from '../ArticleEntry/ArticleEntryTemplate';

const Root = styled.div``;

const articleContext = article => {
  const {slug, category} = article;
  const articleLink = `/articles/${slug}`;
  const categoryTitle = category?.title;
  const categoryLink = `/article-categories/${category?.slug}`;
  return {articleLink, categoryTitle, categoryLink};
};

const ArticlesListTemplate = ({articles}) => (
  <Root>
    {articles.map(article => (
      <ArticleEntryTemplate
        key={article.id}
        {...article}
        {...articleContext(article)}
      />
    ))}
  </Root>
);

export default ArticlesListTemplate;
